/*
Theme Name: Bianca - Not Today Satan
Version: 0.1.0
Author: Cocoapawn LLC
Author URI: http://cocoapawn.me/
*/

$font_heading: 'Sorts Mill Goudy', serif;
$font_body: 'Open Sans', sans-serif;


/* -- BROWSER RESETS -------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure,
figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video {margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline;}
a {text-decoration: none;color: #000;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {display: block;}
body {line-height: 1;height: 100%;background-color: #fff;color: #000;text-align: center;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: '';content: none;}
table {border-collapse: collapse;border-spacing: 0;}
input {margin: 0;}
/* Form reset */
input, textarea {-webkit-appearance: none;-webkit-border-radius: 0;outline: none;}

/* -- GENERAL STYLING ------------------------------------------------------- */
body, header, main, footer, div, span, p, a {
	box-sizing:border-box;
}
body {
	background-color: #000;
	height: 100%;
	font-family: $font_body;
	font-size: 15px;
	line-height: 21px;
	letter-spacing: 0.35px;
	color: #222;
}
main {
	text-align: left;
}
p {
	margin-bottom: 30px;
}
h1, h3, h5 {
	font-family: $font_heading;
	margin-bottom: 15px;
}
h2, h4, h6 {
	font-family: $font_heading;
	margin-bottom: 15px;
}
h1 {
	font-size: 36px;
	line-height: 42px;
}
h2 {
	font-size: 36px;
	line-height: 42px;
}
h3 {
	font-size: 24px;
	line-height: 30px;
}
h4 {
	font-size: 24px;
	line-height: 30px;
}
h5 {
	font-size: 18px;
	line-height: 21px;
}
h6 {
	font-size: 18px;
	line-height: 21px;
}
strong {
	font-weight: 700;
	text-transform: uppercase;
}

/* -- HEADER  --------------------------------------------------------------- */
header {
	max-width: 1100px;
	margin: 0 auto;
	background-color: #000;
	padding: 10px 0;
	text-align: left;
}
#bianca-logo {
	display: inline-block;
	line-height: 30px;
	height: 30px;
	width: 28.181818%;
	padding: 0px 2.727272%;
	vertical-align: top;
}
#bianca-logo a {
	display: block;
	background: url('img/logo.png') center center no-repeat;
	background-size: contain;
	text-indent: -9999px;
}
#top-nav {
	display: inline-block;
	vertical-align: top;
	line-height: 30px;
	padding: 0 2.727272%;
	width: 71.818181%;
	border-left: 1px solid #fff;
}
#top-nav ul li {
	display: inline;
	padding-right: 10px;
}
#top-nav ul li:last-of-type {
	padding-right: 0px;
}
#top-nav ul li a {
	color: #ccc;
	text-transform: uppercase;
	font-size: 13px;
}
#top-nav ul li a:hover {
	color: #eee;
}
#top-nav ul li.current-menu-item a {
	color: #af2120;
}



/* -- PAGE CONTENT ---------------------------------------------------------- */
body.page-template.home {
	color: #f6ede8;
}
main {
	max-width: 1100px;
	margin: 0 auto;
}
section {
	margin: 0;
	padding:0;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
}
.section-container {
	background-repeat:no-repeat;
}
#contact-form {
	padding: 10px;
	border: 1px solid #bbb;
}
#contact-form input[type='text'], #contact-form textarea {
	height: 24px;
	font-family: $font_heading;
	font-size: 18px;
	padding: 3px 2px;
}
#contact-form textarea {
	height: 72px;
	font-family: $font_heading;
	font-size: 18px;
	padding: 3px 2px;
}
#contact-form .frm_submit {
	text-align: center;
}
#contact-form input[type='submit'] {
	background-color: #fff;
	border: 1px solid #000;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 5px 15px;
}
#gallery {
	margin: 0 auto;
	text-align: center;
}
#roh-tickets {
	width: 175px;
	display: block;
	padding: 3px;
	border: 2px solid #000;
	margin:	0 auto;
	text-align: center;
	background-color: #fff;
	font-weight: 800;
	text-transform: uppercase;
	margin-bottom: 20px;
}
.left-col {
	display: inline-block;
	width: 47%;
	margin-right: 2%;
	vertical-align: top;
}
.right-col {
	display: inline-block;
	width: 47%;
	vertical-align: top;
}
#tour-events {
	text-align: center;
	max-width: 630px;
	margin: 0 auto;

}
.tour-content-intro-wrap {
	padding: 30px;
	margin: 24px auto 12px;
	max-width: 630px;
	background-color: rgba(0,0,0,0.5);
	color: #fff;
}
.tour-country-wrap {
	text-align: center;
	max-width: 630px;
	padding-top: 30px;
	padding-bottom: 30px;
	margin: 0 auto 12px;
	background-color: rgba(0,0,0,0.5);
	color: #fff;
}
.tour-country-title {
	font-family: $font_heading;
	text-transform: uppercase;
	font-style: italic;
	font-size: 18px;
	margin-bottom: 12px;
}
.tour-country-announce {
	font-size: 14px;
	padding: 0 30px;
	margin-bottom: 18px;
}
.tour-event {
	//font-weight: 700;
}
.tour-event-info-wrap {
	display: inline-block;
	width: 70%;
	overflow: hidden;
	line-height: 30px;
	vertical-align: top;
	text-align: left;
}
.tour-event-info {
	white-space: nowrap;
	overflow: hidden;
}
.tour-event-link-wrap {
	display: inline-block;
	text-align: right;
	line-height: 30px;
	vertical-align: top;
}
.tour-event-link {

}
.tour-event-link a {
	display: inline-block;
   	font-size: 13px;
	padding: 0px 8px;
	background-color: rgba(255,255,255,0.2);
	border: 1px solid #ddd;
	border-radius: 3px;
	line-height: 22px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
}
.tour-event-link a:hover {
	background-color: rgba(255,255,255,0.5);
}




/* -- ROLODEX --------------------------------------------------------------- */
// .dcsns-content {
// 	position: absolute;
// 	bottom: -59px;
// 	right: 3%;
// 	overflow: hidden;
// 	height: 300px;
// }
// .dcsns-content .stream {
// 	display: inline-block;
// 	padding: 0px 40px;
// 	overflow: hidden;
// 	background: url('img/r-card_bg.png') no-repeat center center;
// 	background-size: contain;
// 	width: 498px;
// 	height: 294px;
// 	position: relative;
// }
// .panel {
// 	display: none;
// 	width: 371px;
// 	height: 230px;
// 	margin: 0;
// 	position: absolute;
// 	top: 4px;
// 	left: 54px;
// 	-webkit-perspective: 1200px;
// 	   -moz-perspective: 1200px;
// 			perspective: 1200px;
// }
// .panel.curr {
// 	display: block;
// 	z-index: 25;
// }
// .panel.next {
// 	display: block;
// 	z-index: 20;
// }
// .panel .dcsns-li {
// 	background-color: transparent;
// 	background: url('img/r-card.png') no-repeat center center;
// 	background-size: contain;
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: inherit;
// 	height: inherit;
// 	text-align: center;
// 	-webkit-transform: rotateX(0deg);
// 	   -moz-transform: rotateX(0deg);
// 			transform: rotateX(0deg);
// 	-webkit-transform-style: preserve-3d;
// 	   -moz-transform-style: preserve-3d;
// 			transform-style: preserve-3d;
// 	-webkit-transform-origin: 0 234px;
// 	   -moz-transform-origin: 0 234px;
// 			transform-origin: 0 234px;
// 	-webkit-transition: all .4s ease-in-out;
// 			transition: all .4s ease-in-out;
// 	-webkit-transition-duration: 1s;
// 	   -moz-transition-duration: 1s;
// 			transition-duration: 1s;
// }
// .panel.flip .dcsns-li {
// 	-webkit-transform: rotateX(-180deg);
// 	   -moz-transform: rotateX(-180deg);
// 			transform: rotateX(-180deg);
// }
// .panel.flip .action {display: none;}
// .dcsns-li .inner {
// 	position: absolute;
// 	top: 50px;
// 	left: 12px;
// 	right: 12px;
// 	bottom: 35px;
// 	overflow: hidden;
// 	color: #222;
// }
// .dcsns-li .inner a {
// 	color: #222;
// }
// .section-title {
// 	display: block;
// 	position: absolute;
// 	top: 0px;
// 	left: 0;
// 	right: 0;
// 	min-height: 17px;
// 	height: 18px;
// 	line-height: 18px;
// 	overflow: hidden;
// 	color: #000;
// 	font-weight: 600;
// 	font-size: 16px;
// }
// .section-thumb {
// 	display: inline-block;
// 	float: left;
// 	color: #222;
// 	margin-top: 24px;
// 	max-height: 120px;
// }
// .section-thumb a img {
// 	max-height: 120px;
// }
// .section-text {
// 	display: inline-block;
// 	font-size: 12px;
// 	text-align: left;
// 	line-height: 12px;
// 	color: #222;
// 	width: 85%;
// 	margin-top: 24px;
// }
// .section-user, .section-share {
// 	display: none;
// }
// .panel .dcsns-li>a {
// 	position: absolute;
// 	top: 9px;
// 	left: 135px;
// }
// .section-intro {
// 	color: #333;
// 	position: absolute;
// 	top: 12px;
// 	left: 170px;
// 	font-size: 15px;
// 	font-family: $font_heading;
// }
// .section-intro a {
// 	color: #333;
// }



/* -- CTA BLOCKS ------------------------------------------------------------ */
#cta-blocks {
	max-width: 1100px;
	margin: 0 auto;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	color: #fff;
	text-align: center;
}
.cta-block {
	margin-bottom: -5px;
	display: inline-block;
	border: 1px solid #fff;
	width: 33.33333%;
	height: 300px;
	background: url('img/cta_bg.png') top left repeat;
	position: relative;
	z-index: 0;
}
.cta-block:before {
	content: '';
	background: url('img/cta_bg_overlay.png') repeat;
	background-size: contain;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
.cta-block-inner {
	float:left;
	width:100%;
	height:100%;
	background-position: center 0%;
	background-repeat: no-repeat;
	background-size: 100%;
}
.cta-btns {
	position: relative;
}
.cta-btn {
	position: absolute;
	bottom: 24px;
	left: 50%;
	width: 150px;
	margin-left: -75px;
	height: 25px;
	background-color: #000;
	color: #fff;
	border: 2px solid #ddd;
	text-transform: uppercase;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	&:hover {
		background-color: #790A09;
	}
}
.cta-btn.cta-sec-btn {
	bottom: 60px;
}
.cta-social-icons {
	position: absolute;
	bottom: 10px;
	left: 50%;
	padding: 10px;
	width: 300px;
	margin-left: -150px;
}
.cta-social-icon {
	display: inline-block;
	height: 40px;
	width: 40px;
	margin: 10px;
	text-indent: -9999px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}



/* -- FOOTER ---------------------------------------------------------------- */
footer {
	max-width: 1100px;
	margin: 0 auto;
	color: #888;
	font-size: 10px;
	text-align: right;
	padding: 6px 0;
}


/*--------------------------------------------------------------------*/
/*	MOBILE NAV
/*--------------------------------------------------------------------*/
#mobileNav {
  display: none;
  width: 270px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  z-index: 2000;
  -webkit-transform: translate(270px, 0);
  -ms-transform: translate(270px, 0);
  transform: translate(270px, 0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

body.showMobileNav #mobileNav {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

#mobile-logo {
  display: none; }

#mobile-logo a {
  display: block;
  position: fixed;
  top: 5px;
  left: 5px;
  height: 38px;
  width: 140px;
  background: url("imgs/preview-of-homes.png") no-repeat center center;
  background-size: contain;
  text-indent: -9999px;
  z-index: 2000; }

#mobileNav .tab {
  position: absolute;
  top: 0;
  left: -60px;
  height: 60px;
  width: 60px;
  padding: 12px;
  color: #fff;
  background-color: #000;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 0.3px;
  line-height: 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1000;
}

body.showMobileNav #mobileNav .tab {
  color: #fff;
  //background-color: #C4A974;

  background: rgb(175,33,32);
  background: -moz-linear-gradient(top, rgba(175,33,32,1) 0%, rgba(71,11,11,1) 100%);
  background: -webkit-linear-gradient(top, rgba(175,33,32,1) 0%,rgba(71,11,11,1) 100%);
  background: linear-gradient(to bottom, rgba(175,33,32,1) 0%,rgba(71,11,11,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af2120', endColorstr='#470b0b',GradientType=0 );

}

#mobileNav .tab {
  text-align: center;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

#mobileNav .tab:before {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 14px;
  right: 14px;
  height: 4px;
  border-top: #fff solid 4px;
  border-bottom: #fff solid 4px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

#mobileNav .tab:after {
  content: '';
  position: absolute;
  bottom: 12px;
  left: 14px;
  right: 14px;
  border-bottom: #fff solid 4px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

body.showMobileNav #mobileNav .tab:before {
  bottom: 15px;
  left: 13px;
  right: 14px;
  height: 4px;
  border-top: #fff solid 4px;
  border-bottom: none;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

body.showMobileNav #mobileNav .tab:after {
  bottom: 19px;
  left: 14px;
  right: 13px;
  border-bottom: #fff solid 4px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#mobileNav-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 24px 2px;
  width: 266px; }

.mobileNavGroup {
  margin-bottom: 50px;
  text-align: left;
  color: #fff; }

.mobileNavGroup ul {
  list-style: none;
  padding-left: 0; }

.mobileNavGroup li {
  padding: 5px 18px;
  font-size: 15px;
  font-weight: 300;
  border-bottom: 1px solid #929497;
  line-height: 30px;
  letter-spacing: 0.5px; }

.mobileNavGroup li a {
  color: #fff;
  line-height: 30px; }

.mobileNavGroupTitle {
  padding-left: 18px;
  font-size: 12px;
  color: #929497;
  font-weight: 300;
  margin-bottom: 10px; }

.mobileNavGroup .phone {
  -webkit-hyphens: none; }



/* -- RESPONSIVE ------------------------------------------------------------ */
@media screen and (max-width: 1017px) {
	#bianca-logo a {
		background: url('img/logowrap.png') center center no-repeat;
		background-size: contain;
	}
	#bianca-logo {
		line-height: 60px;
		height: 60px;
	}
}
@media screen and (max-width: 899px) {
	.left-col {
		display:block;
		width:100%;
		margin-right:0;
	}
	.right-col {
		display:block;
		width:100%;
		margin-right:0;
	}
}
@media screen and (max-width: 767px) {
	.cta-block {
		width: 50%;
	}
}
@media screen and (max-width: 575px) {
	#mobileNav {
		display: block;
	}
	body.showMobileNav {
		overflow: hidden;
	}
	header {
		height: 60px;
		padding: 5px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
	main {
		margin-top: 60px;
	}
	#top-nav {
		display: none;
	}
	#bianca-logo {
		width: 65%;
		line-height: 50px;
		height: 50px;
	}
	#bianca-logo a {
		background: url('img/logo.png') center center no-repeat;
		background-size: contain;
	}
	.dcsns-content {
		right: 50%;
		margin-right: -259px;
	}
	.tour-event {
		margin-bottom: 20px;
	}
	.tour-event-info-wrap {
		display: block;
		width: 100%;
		text-align: center;
	}
	.tour-event-info {
		white-space: normal;
		overflow: visible;
	}
	.tour-event-link-wrap {
		display: block;
		width: 100%;
		text-align: center;
	}
	.dots {
		display: none;
	}
}
@media screen and (max-width: 409px) {
	.cta-block {
		width: 100%;
	}
	.dcsns-content {
		bottom: -91px;
		height: 270px;
		right: 50%;
		margin-right: -230px;
	}
	.dcsns-content .stream {
	padding: 0px 40px;
		width: 449px;
		height: 254px;
	}
	.panel {
		width: 325px;
		height: 198px;
	top: 4px;
	left: 54px;
	-webkit-perspective: 1200px;
	   -moz-perspective: 1200px;
			perspective: 1200px;
	}
	.panel .dcsns-li>a {
		left: 115px;
	}
	.section-intro {
		left: 145px;
	}
	.dcsns-li .inner {
		top: 45px;
		left: 10px;
		right: 10px;
	}
	.section-title {
		font-size: 14px;
	}
	.section-text {
		width: 80%;
		float: right;
	}
}
/* High density display */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {

}



#social-stream{
	width:215%;
	margin:5px;
	position:absolute;
	height:160px;
	z-index: 5;
	overflow: hidden;
	margin-left:-228px;
	bottom: 0;
}
